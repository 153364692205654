/* ExternalFunctionalityPage.module.css */
.efp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin-top:10vh ;
  background-color: rgba(38, 37, 37, 0.2); /* Semi-transparent background */
  padding: 20px;
  backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
  border-radius: 40px; /* Optional: adds rounded corners */
}

.efp-card {
  background-color: #181818;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  text-align: center;
  max-width: 500px;
  width: 100%;
  color: #00ff74;
}

.efp-heading {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: #00ff74;
  font-weight: bold;
}

.efp-paragraph {
  font-size: 1.0rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #c0c0c0; /* Lighter green for paragraph text */
}

.efp-button {
  padding: 10px 30px;
  font-size: 1.0rem;
  font-weight: bold;
  background-color: #00ff74;
  color: #181818;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Gotham-Black;
}

.efp-button:hover {
  background-color: #00e567; /* Slightly darker green on hover */
}

.efp-button:active {
  background-color: #00c450; /* Even darker on click */
}

@font-face {
  font-family: 'Gotham-Black';
  src: url('./ic/fonts/Gotham-Black.woff') format('woff');

  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}
