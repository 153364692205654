.gta5-container {
  text-align: center;
  margin-top: 10vh;
  position: relative; /* Ensures the shape stays within the container */
}

.gta5-title {
  color: #00ff74;
  font-size: 40px;
  margin-bottom: 50px; /* Adds space between the title and the shape/message */
}

.gta5-message-wrapper {
  margin-top: 50px;
}

.gta5-message {
  color: #494747;
  font-size: 25px;
  transition: opacity 1s ease-in-out;
}

.gta5-message.hidden {
  opacity: 0;
}

.gta5-message.visible {
  opacity: 1;
}

/* Rotating shape animation */
.gta5-shape {
  width: 100px;
  height: 100px;
  background-color: #00ff74;
  border-radius: 50%;
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
  animation: rotateShape 5s linear infinite, floatShape 4s ease-in-out infinite;
}

@keyframes rotateShape {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes floatShape {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -20px);
  }
}
