.animated-text {
    display: flex;
    align-items: center;
    font-size: 1.5em; /* Adjust size as needed */
    position: relative;
    white-space: nowrap; /* Prevent text wrapping */
    font-style: italic; /* Make the text italic */
    font-smooth: unset;
    color: #262525;
    font-weight: bold;
    font-size: 30px;

}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}


@keyframes blink {
    50% {
        opacity: 0;
    }
}

