/* General styling for the app */
/*----------------overlay------------------------*/

/* Blurred background when overlay is active */
.blur-background {
  filter: blur(10px);
  transition: filter 0.3s ease-in-out;
}

/* Subscription overlay styles */
.subscription-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.subscription-content {
  background-color: #181818;
  padding: 30px;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
}

.subscribe-button {
  background-color: #00ff74;
  color: #181818;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-family: Gotham-Black;
}

.subscribe-button:hover {
  background-color: #00cc5c;
  color: white;
}

/* Loading animation styles */
.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #00ff74; /* Green */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-animation p {
  color: white;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
}

.status-message {
  color: #ffcc00; /* Yellow to grab attention */
  margin-top: 10px;
  font-size: 16px;
}

/* Close overlay button */
.close-overlay-button {
  background-color: #00ff74;
  color: #181818;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-family: Gotham-Black;
}

.close-overlay-button:hover {
  background-color: #00cc5c;
  font-family: Gotham-Black;
}

.final-caption {
  color: white;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

h3 {

  font-size: 30px;
  font-family:'Gotham-Black';

}



/*_________________________________________________login & sinup buttons______________________*/

/* Navbar styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    
    /* Background with transparency and blur */
    background-color: rgba(24, 24, 24, 0.2); /* Semi-transparent background */
    border-radius: 30px; /* Rounded corners */
    
    backdrop-filter: blur(1px); /* Apply blur effect */
    /* Optional: Add a box shadow for better separation */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.navbar-logo {
  height: 50px;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: Gotham-black;
}

.home-link {
  background-color: #262525;
  color: #939090;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.home-link:hover {
  background-color: #00ff74;
  color: #fff;
}

.navbar-links a:hover {
  background-color: #262525;
}

.auth-link {
  background-color: #00ff74;
  color: #181818;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.auth-link:hover {
  background-color: #00cc5c;
  color: #fff;
}


/*_____________________________________________________________________________________________*/

@font-face {
  font-family: 'Gotham-Black';
  src: url('./components/ic/fonts/Gotham-Black.woff') format('woff');

  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-image: url('./components/images/bgb.webp'); /* Path to your image */
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  background-repeat: repeat; /* Prevents the image from repeating */
  color: white;
  min-height: 100vh;
  padding: 2rem;
}

.logo {
  width: 50px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below the image */
}

h1 {
  margin-bottom: 4rem;
  margin-top: 4rem;
  font-family: 'Gotham-Black';
  color: #00ff74;
}

/* Card container styling */
.card-container {
  display: flex;
  justify-content: space-evenly; /* Space the cards evenly */
  flex-wrap: wrap; /* Allow wrapping of cards */
  gap: 20px; /* Space between cards */
}

/* Individual card styling */
.card {
  width: 250px; /* Width of the card */
  height: 450px; /* Height of the card */
  background-size: cover; /* Cover the entire card with the image */
  background-position: center; /* Center the image */
  position: relative; /* For absolute positioning of overlay */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
  cursor: pointer; /* Change cursor on hover */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.card:hover {
  transform: scale(1.05); /* Scale up on hover */
}

/* Dark overlay and text */
.overlay {
  position: absolute; /* Position overlay over the card */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: 'Gotham-Black';
}

.overlay h2 {
  color: white; /* White text color */
  font-size: 24px; /* Font size for the game name */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* On hover, darken image and show text */
.card:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.card:hover .overlay h2 {
  opacity: 1; /* Show game name on hover */
}

/* Button styling */
.card-button {
  position: absolute; /* Position at the bottom */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 13.33%; /* Height is 1/3 of the card */
  background-color: rgba(0, 255, 116, 0.4); /* Semi-transparent button color */
  border: none; /* Remove default border */
  padding: 10px 0; /* Vertical padding */
  color: white; /* White text color */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Disable interaction initially */
  font-family: 'Gotham-Black';
}

.card:hover .card-button {
  opacity: 1; /* Show button on hover */
  pointer-events: auto; /* Enable interaction on hover */
}

/* Fade effect on hover */
.card:hover .card-button {
  background-color: rgba(0, 255, 116, 0.7); /* Darker button color on hover */
}

.card-button:hover {
  background-color: rgba(0, 255, 116, 0.8); /* Darken button on hover */
}

.back-button {
  display: inline-block; /* Make it behave like a button */
  padding: 10px 20px; /* Add some padding */
  font-size: 16px; /* Font size */
  color: #262525; /* Text color */
  background-color: #00ff74; /* Background color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  text-align: center; /* Center the text */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  font-family: 'Gotham-Black';

}

.back-button:hover {
  background-color: #00cc5a; /* Darken the background on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

/* Report Issue Button--------------------------------------------------------------------------------- */
.report-issue-button {
  background-color: #ffcc00;
  color: #181818;
  padding: 10px 20px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Gotham-Black';
  margin-bottom: 20px;
}
.report-issue-button:hover {
  background-color: #e6b800;

}

/* Report Modal */
.report-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.report-modal-content {
  background-color: #181818;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 500px;
  color: #fff;
}
.report-modal-content h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #00ff74;
  font-family: 'Gotham-Black';
}
.report-modal-content input,
.report-modal-content textarea {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  background-color: #252525;
  color: white;
}
.report-modal-content button[type="submit"] {
  background-color: #00ff74;
  color: #181818;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Gotham-Black';
}
.report-modal-content button[type="submit"]:hover {
  background-color: #00cc5c;
}
.close-modal-button {
  background-color: transparent;
  color: #ffcc00;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin-top: 15px;
}
.report-status {
  color: #ffcc00;
  font-size: 16px;
  margin-top: 15px;
}
