/* FIFA.css */

@font-face {
  font-family: 'Gotham-Black';
  src: url('./ic/fonts/Gotham-Black.woff') format('woff');

  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}

/* Card container styling */
.card-container {
  display: flex;
  justify-content: space-evenly; /* Space the cards evenly */
  flex-wrap: wrap; /* Allow wrapping of cards */
  gap: 20px; /* Space between cards */
  margin-top: 20px;
}

h2 {

  font-family: 'Gotham-Black';
  font-size: 2.5rem;
  color: #00ff74;
}

p {

  font-family: 'Gotham-Black';
  font-size: 1.0rem;
  
}



/* Individual card styling */
.card {
  width: 250px; /* Width of the card */
  height: 450px; /* Height of the card */
  background-size: cover; /* Cover the entire card with the image */
  background-position: center; /* Center the image */
  position: relative; /* For absolute positioning of overlay */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
  cursor: pointer; /* Change cursor on hover */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.card:hover {
  transform: scale(1.05); /* Scale up on hover */
}

/* Overlay styling */
.card-overlay {
  position: absolute; /* Position overlay over the card */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
  color: white;
  font-family: 'Gotham-Black';
}

.card:hover .card-overlay {
  opacity: 1; /* Show overlay on hover */
}

.card-image {
  width: 100%; /* Make the image full width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Match card border radius */
}

